//----------------------------------------------------------//
//	CARD
//----------------------------------------------------------//
.card {
  box-shadow: $box-shadow-with-border;
  border: 0;
  &[class*="bg-"] {
    box-shadow: none !important;
  }
}
.card-img-top img {
  border-top-left-radius: $card-inner-border-radius;
  border-top-right-radius: $card-inner-border-radius;
}
.card-img-top .plyr {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 991.98px) {
  .card-md-none {
    background: none;
    border-radius: 0;
    .card-body {
      padding: 0;
    }
  }
}
//----------------------------------------------------------//
//	ACCORDION
//----------------------------------------------------------//
.accordion-wrapper .card {
  margin-bottom: 1.25rem;
  &.plain {
    background: none;
    border: 0;
    margin-bottom: 0;
    box-shadow: none;
    .card-body {
      padding: 0 0 0 1.1rem;
    }
    .card-header {
      padding: 0 0 0.8rem 0;
      button:before {
        left: 0;
      }
    }
  }
}
.accordion-wrapper .card-header {
  margin-bottom: 0;
  background: none;
  border: 0;
  padding: 0.9rem 1.3rem 0.85rem;
  button {
    cursor: pointer;
    width: 100%;
    padding: 0 0 0 1.2rem;
    text-align: left;
    margin: 0;
    border: 0;
    @include font-size(0.85rem);
    font-weight: $font-weight-700;
    color: $main-color;
    @include transition(all 150ms ease-in-out);
    background: none;
    &:before {
      font-family: $font-family-custom-icons;
      content: $icon-caret-up;
      @include font-size(0.3rem);
      font-weight: normal;
      width: 1rem;
      display: inline-block;
      margin-top: 0.4rem;
      color: $main-color;
      position: absolute;
      left: 1.3rem;
    }
    &.collapsed {
      color: $navy;
      &:before {
        content: $icon-caret-down;
      }
    }
    &:hover {
      color: $main-color;
    }
  }
}
.accordion-wrapper .card-body {
  padding: 0 1.25rem 0.25rem 2.5rem;
}
//----------------------------------------------------------//
//	SWATCHBOOK
//----------------------------------------------------------//
.swatchbook .card-img-top .col {
  height: 5rem;
}
.swatchbook .card-img-top .col:first-child {
  border-top-left-radius: $border-radius;
}
.swatchbook .card-img-top .col:last-child {
  border-top-right-radius: $border-radius;
}