//----------------------------------------------------------//
//	CLOSE BUTTON
//----------------------------------------------------------//
.btn-close {
  padding: $btn-close-padding-y $btn-close-padding-x;
  color: $btn-close-color;
  background: none;
  border: 0;
  line-height: 1;
  @include transition($btn-transition);
  &:before {
    font-family: $font-family-unicons;
    content: $icon-close;
    @include font-size(1.2rem);
    width: $btn-close-width;
    height: $btn-close-height;
  }
  &:hover {
    color: $btn-close-hover-color;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: $btn-close-disabled-opacity;
  }
}
.btn-close-white {
  filter: $btn-close-white-filter;
}
.modal .btn-close {
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
}
[class*="offcanvas-"] .btn-close {
  color: rgba($gray-900, 0.8);
  &:hover {
    color: rgba($gray-900, 1);
  }
}