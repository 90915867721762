//----------------------------------------------------------//
//	LIGHTBOX
//----------------------------------------------------------//
.lg-backdrop {
  background: $lightbox-bg;
}
.lg-outer {
  .lg-img-wrap {
    padding: 0.5rem;
  }
  .lg-item {
    background: none;
    &.lg-complete:after {
      display: none;
    }
  }
}
.lg-outer .lg-toolbar {
  background: none;
}
.lg-image,
.lg-object {
  border-radius: $border-radius !important;
}
.lg-actions {
  .lg-next,
  .lg-prev {
    border: 0;
    background: $lightbox-icon-bg;
    color: $lightbox-icon-color;
    @include font-size(1.2rem);
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
    border-radius: 100%;
    margin-top: 0;
    padding: 0;
    box-shadow: none;
    transition: $btn-transition;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    &:before {
      font-family: $font-family-unicons;
    }
    &:hover {
      background: $lightbox-icon-hover-bg;
    }
    &:after {
      display: none;
    }
  }
  .lg-prev {
    left: 1rem;
    &:before {
      content: $icon-prev;
    }
  }
  .lg-next {
    right: 1rem;
    &:before {
      content: $icon-next;
    }
  }
}
.lg-toolbar .lg-icon.lg-close {
  border: 0;
  background: $lightbox-icon-bg;
  color: $lightbox-icon-color;
  @include font-size(1.2rem);
  width: 2.2rem;
  height: 2.2rem;
  line-height: 2.2rem;
  border-radius: 100%;
  margin-top: 0;
  padding: 0;
  box-shadow: none;
  transition: $btn-transition;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lg-toolbar .lg-icon:hover {
  background: $lightbox-icon-hover-bg;
}
.lg-toolbar .lg-icon.lg-close:after {
  font-family: $font-family-unicons;
}
.lg-toolbar .lg-icon.lg-close:after {
  content: $icon-close;
}
.lg-toolbar .lg-icon.lg-close {
  margin-right: 1rem;
  margin-top: 1rem;
}
.lg-dropdown {
  z-index: 3051;
}
.lg-sub-html {
  padding: 0.5rem 0.5rem 1rem;
  background: linear-gradient(180deg,
      rgba($black, 0),
      rgba($gray-900, 0.4) 100%);
  p {
    @include font-size($font-size-base);
    color: $white;
    margin: 0;
  }
  h5 {
    color: $white;
    margin-bottom: 0.2rem;
  }
}