//----------------------------------------------------------//
//	DROPDOWN ANIMATION
//----------------------------------------------------------//
@keyframes show-animation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 0.5rem, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes hide-animation {
  0% {
    transform: translate3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 1);
    opacity: 0;
  }
}
//----------------------------------------------------------//
//	FADEIN ANIMATION
//----------------------------------------------------------//
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
//----------------------------------------------------------//
//	PRICING ANIMATION
//----------------------------------------------------------//
@keyframes priceFade {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(80%);
    transform: translateY(80%);
  }
}
@keyframes priceShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-80%);
    transform: translateY(-80%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
//----------------------------------------------------------//
//	RIPPLE ANIMATION
//----------------------------------------------------------//
@keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}//----------------------------------------------------------//
//	LOADER ANIMATION
//----------------------------------------------------------//
@keyframes loader-scale {
  0% {
      transform: scale(0);
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}
//----------------------------------------------------------//
//	PAGE LOADER
//----------------------------------------------------------//
.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $body-bg;
  z-index: 9999999;
}
.lg-outer .lg-item:after,
.loader,
.tp-loader.spinner,
.hero-slider:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 2.5rem;
  height: 2.5rem;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  background: none;
  border: 4px solid $main-color;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 50%;
  -webkit-animation: loader-scale 1s ease-out infinite;
  animation: loader-scale 1s ease-out infinite;
}
.hero-slider-wrapper.bg-dark .hero-slider:before,
.lg-outer .lg-item:after {
  border-color: $white;
}