//----------------------------------------------------------//
//	WRAPPERS
//----------------------------------------------------------//
.wrapper-border {
  border-bottom: 1px solid $border-light;
  &.bg-dark {
    border-color: rgba($white, 0.08);
  }
}
footer.bg-dark {
  background: $gray-800 !important;
}
//----------------------------------------------------------//
//	BACKGROUND IMAGE
//----------------------------------------------------------//
.image-wrapper {
  background: no-repeat center center;
  background-size: cover;
  position: relative;
  z-index: 0;
  &:not(.mobile) {
    background-attachment: fixed !important;
  }
  &.bg-auto {
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }
  &.bg-full {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }
  &.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll !important;
  }
  &.bg-overlay:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba($gray-900, 0.5);
  }
  &.bg-overlay-300:before {
    background: rgba($gray-900, 0.3);
  }
  &.bg-overlay-400:before {
    background: rgba($gray-900, 0.4);
  }
  &.bg-overlay:not(.bg-content) *,
  &.bg-overlay.bg-content .content {
    position: relative;
    z-index: 2;
  }
  &.card:before {
    border-radius: $border-radius !important;
  }
}
@media (max-width: 991.98px) {
  .image-wrapper.bg-full {
    background-size: cover !important;
  }
}
@media (max-width: 767px) {
  .bg-map {
    background: none !important;
  }
}
//----------------------------------------------------------//
//	BACKGROUND VIDEO
//----------------------------------------------------------//
.video-wrapper-inner {
  height: 30rem;
  width: 100%;
}
.video-wrapper {
  position: relative;
  .content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: rgba($gray-900, 0.5);
  }
  video {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: baseline;
    background-size: cover;
  }
}
video::-webkit-media-controls {
  display: none !important;
}
//----------------------------------------------------------//
//	ANGLED WRAPPERS
//----------------------------------------------------------//
.wrapper.angled {
  position: relative;
  border: 0;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    z-index: 0;
    border: 0 solid transparent;
  }
  &.lower-end:after {
    border-right-color: transparent;
    border-right-width: 100vw;
    border-bottom-width: 4rem;
    bottom: -4rem;
  }
  &.lower-start:after {
    border-left-color: transparent;
    border-left-width: 100vw;
    border-bottom-width: 4rem;
    bottom: -4rem;
  }
  &.upper-end:before {
    border-right-color: transparent;
    border-right-width: 100vw;
    border-top-width: 4rem;
    top: -4rem;
  }
  &.upper-start:before {
    border-left-color: transparent;
    border-left-width: 100vw;
    border-top-width: 4rem;
    top: -4rem;
  }
}
footer.bg-dark.angled {
  &.lower-end:after,
  &.upper-end:before {
    border-right-color: $gray-800 !important;
  }
  &.lower-start:after,
  &.upper-start:before {
    border-left-color: $gray-800 !important;
  }
}