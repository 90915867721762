//----------------------------------------------------------//
//	FORMS
//----------------------------------------------------------//
input,
textarea,
select {
  -webkit-appearance: none !important;
}
.form-label-group {
  position: relative;
}
.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  color: $form-label-color;
  border: 1px solid transparent;
  transition: all 0.1s ease-in-out;
  @include font-size($input-font-size);
  text-align: left;
  padding: $input-padding-y $input-padding-x;
  pointer-events: none;
}
.form-label-group .form-control::-webkit-input-placeholder {
  color: transparent;
}
.form-label-group .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-label-group .form-control::-ms-input-placeholder {
  color: transparent;
}
.form-label-group .form-control::-moz-placeholder {
  color: transparent;
}
.form-label-group .form-control::placeholder {
  color: transparent !important;
}
.form-label-group .form-control:not(:placeholder-shown) {
  padding-top: calc(#{$input-padding-y} + #{$input-padding-y} * (2 / 3));
  padding-bottom: calc(#{$input-padding-y} / 3);
}
.form-label-group .form-control:not(:placeholder-shown)~label {
  padding-top: calc(#{$input-padding-y} / 3);
  padding-bottom: calc(#{$input-padding-y} / 3);
  @include font-size(0.6rem);
}
form.dark-fields .form-control {
  background: rgba($white, 0.03);
  border-color: rgba($white, 0.1);
  color: $gray-300;
  &:focus {
    border-color: rgba($main-color, 0.5);
  }
  &::placeholder {
    color: $gray-300;
  }
}
.search-form {
  position: relative;
  .form-control {
    margin: 0;
    padding-right: 2.25rem !important;
  }
  &:before {
    content: $icon-search;
    font-family: $font-family-unicons;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    @include font-size(0.9rem);
    color: $input-placeholder-color;
    z-index: 1;
  }
}
.form-check-input {
  box-shadow: $box-shadow;
}
.help-block.with-errors {
  color: $error;
  margin-top: 0.25rem;
}
.form-select {
  background-clip: padding-box;
}
.input-group>.form-control:focus, .input-group>.form-select:focus {
  z-index: 0;
}