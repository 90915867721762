// Theme Colors
$blue: #3f78e0;
$purple: #747ed1;
$violet: #a07cc5;
$pink: #d16b86;
$red: #e2626b;
$orange: #f78b77;
$yellow: #fab758;
$green: #6bbea3;
$leaf: #7cb798;
$aqua: #54a8c7;
$navy: #343f52;
$ash: #9499a3;